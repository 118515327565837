"use strict";

window.jQuery = window.$ = require("jquery");
var processInclude = require("base/js/util");
require('base/js/components/spinner');
$(document).ready(function () {
    processInclude(require("../../minifiedJs/main"));
    processInclude(require('./custom/custom'));
});
require('base/js/thirdParty/bootstrap');


